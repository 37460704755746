import { Controller } from "stimulus"
import SignaturePad from "signature_pad"

export default class extends Controller {

  static signaturePad = null

  resizeCanvas() {
    console.log('rc')
    var canvas = document.getElementById('signatureCanvas')
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    if(this.signaturePad) {
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }
  }

  initCanvas() {
    console.log('init canvas', this.signaturePad)

    
    if(this.signaturePad != undefined){
      return
    }
    var canvas = document.getElementById('signatureCanvas')

    canvas.width = $(".modal-body").width() - 50;
    canvas.height = (canvas.width / 2) - 50;

    this.signaturePad = new SignaturePad(canvas)
    new ShipmentBillSignature(this.signaturePad)
    $(window).trigger('resize')
  }

  clearCanvas() {
    //var canvas = document.getElementById('signatureCanvas')
    //var signaturePad = new SignaturePad(canvas)
    this.signaturePad.clear();
  }

  selectSignature(e) {
    var id = $(e.currentTarget).attr('id');
    $("#signatureName").empty().val(id);
  }

  saveSignature(e) {
    e.preventDefault();
    var signatureName = $("#signatureName").val();
    var id = $("#id").val();
    var url = "/shipment_bills/"+id;
    var data = { shipment_bill: { } }
    data.shipment_bill[signatureName] = this.signaturePad.toDataURL()

    $.ajax({
        url: url,
        type: 'PATCH',
        data: data,
        success: function(response){
        
          $('.modal').modal('toggle');
          swal("Exito", "Firma guardada con exito", "success");
        
        },
        error: function(error) {
          
          swal("Error!", error, "error");

        }
    });

  }
  connect() {
    $('#signatureModal').on('shown.bs.modal', this.initCanvas.bind(this))
  }
}
