import { Controller } from "stimulus"
import { randomColor } from "randomcolor";

export default class extends Controller {
  static targets = ['chart', 'indicatorType', 'operationType', 'table',
                    'graphType', 'categories', 'showView', 'form', 'buttonSend',
                    'categoriesLabels', 'costLabel', 'operationTypeLabel']
  initialize() {
    const indicatorContainer = this.element.closest('.draggable-indicator')

    if(indicatorContainer !== null){
      if(indicatorContainer.dataset.loaded === 'true'){
        return;
      }
      indicatorContainer.dataset.loaded = 'true'
    }

    if(this.data.get('client') != 'false') {
      this.path = '/client_app/client_indicators'
    } else {
      this.path = '/indicators'
    }
    if(this.data.has('show')) {
      if(this.hasButtonSendTarget) {
        this.buttonSendTarget.innerHTML = window.locals.loading
        this.buttonSendTarget.disabled = true;
      }
      this.loadDataShow();
    } else {
      this.categoriesSelect =
        $(this.categoriesTarget).selectize({
          onChange: (value, isOnInitialize) => {
            this.updateChart();
          },
          options: window.categoriesOptions.movement_options
        });
      this.loadData();
    }
  }

  updateChartQueries() {
    var buttonLoading = event.target
    this.buttonSendTarget.innerHTML = window.locals.loading
    this.buttonSendTarget.disabled = true;
    $.ajax({
      type: 'GET',
      url: `${this.path}/${this.data.get('show')}/update_chart_queries`,
      data: $(this.formTarget).serialize(),
      success: (data) => {
        const dataset = data.chart_dataset
        this.buttonSendTarget.innerHTML = window.locals.apply
        this.buttonSendTarget.disabled = false;
        this.datasetAjax = dataset.dataset
        this.labels = dataset.labels.flat()
        this.datasetProcessing()
        this.createChart(this.chartType())
      },
    })
  }

  pickAColor(index) {
    return [
      'rgba(255, 99, 132, 0.4)', 'rgba(54, 162, 235, 0.4)',
      'rgba(255, 206, 86, 0.4)', 'rgba(75, 192, 192, 0.4)',
      'rgba(153, 102, 255, 0.4)', 'rgba(255, 159, 64, 0.4)',
      'rgb(62, 187, 181, 0.4)', 'rgb(94, 201, 130, 0.4)',
      'rgb(199, 204, 102, 0.4)', 'rgb(190, 195, 233, 0.4)',
      'rgb(152, 180, 221, 0.4)', 'rgb(177, 110, 207, 0.4)',
      'rgb(55, 164, 60, 0.4)', 'rgb(125, 212, 147, 0.4)',
    ][index]
  }

  chartType() {
    if(this.data.has('show')) {
      return this.data.get('type')
    } else {
      return this.graphTypeTarget.value
    }
  }

  loadData() {
    fetch('render_dummy_json?' + new URLSearchParams({
      type: this.indicatorTypeTarget.value,
      operation: this.operationTypeTarget.value,
      categories: this.categoriesSelect[0].selectize.getValue(),
    }), {
      method: 'get',
    })
    .then(response => response.json())
    .then(data => {
      this.datasetAjax = JSON.parse(data.dataset)
      this.labels = data.labels.flat()
      this.datasetProcessing()
      this.createChart(this.chartType())
    })
  }

  loadDataShow() {
    fetch(`${this.path}/${this.data.get('show')}/collection_service`, {
      method: 'get'
    })
    .then(response => response.json())
    .then(data => {
      if(this.hasButtonSendTarget) {
        this.buttonSendTarget.innerHTML = window.locals.apply
        this.buttonSendTarget.disabled = false
      }
      this.datasetAjax = data.chart_dataset.dataset
      this.labels = data.chart_dataset.labels.flat()
      this.table = data.table_data
      this.renderTable()
      this.datasetProcessing()
      this.createChart(this.chartType())
    })
  }

  renderTable() {
    if (this.hasTableTarget) {
      const thr = $('<tr class="bg-primary text-light"></tr>')
      const categoriesLabels = this.categoriesLabelsTarget.value.split(',')
      for(const h of categoriesLabels){
        $(thr).append(`<th><strong>${h}</strong></th>`)
      }
      $(thr).append(`<th class='text-right'><strong>${this.operationTypeLabelTarget.value}</strong></th>`)
      $(thr).append(`<th class='text-right'><strong>${this.costLabelTarget.value}</strong></th>`)
      $(this.tableTarget).html(thr)

      for(const row of this.table){
        const value = parseFloat(row[0]).toFixed(2)
        const cost = parseFloat(row[1]).toFixed(2)
        const categories = row.slice(2)
        const tr = $('<tr></tr>')
        var tds = ''
        for(const h of categoriesLabels){
          let catValue = categories.shift()
          if(h === 'Mes') catValue = moment(catValue).format('YYYY-MM')
          tds += `<td>${catValue}</td>`
        }
        tds += `<td class='text-right'>${value}</td>`
        tds += `<td class='text-right'>$${cost}</td>`
        tr.html(tds)
        $(this.tableTarget).append(tr)
      }

      let headerCell = null;

      for ( let i = 0; i <= categoriesLabels.length - 2; i++) {
        for (let row of this.tableTarget.rows) {
          const mainCell = row.cells[i];
          if (headerCell === null || mainCell.innerText !== headerCell.innerText) {
            headerCell = mainCell;
          } else {
            headerCell.rowSpan++;
            mainCell.remove();
          }
        }
        headerCell = null;
      }
    }
  }

  datasetProcessing() {
    this.dataset = []
    Object.keys(this.datasetAjax).forEach( (key) => {
      var colors = [randomColor()]
      this.dataset
        .push(this.splitDateset(key, this.datasetAjax[key], colors))
    })
  }

  updateChart() {
    if(this.data.has('show')){
      this.loadDataShow();
    } else {
      this.loadData();
    }
  }

  createChart(graph) {
    if (graph == 'stacked') {
     var chartType = 'bar';
    } else if (graph == 'areas') {
     var chartType = 'line';
    } else {
     var chartType = graph;
    }
    if(this.chartInstance instanceof Chart) {
      this.chartInstance.destroy();
    }
    this.chartInstance =
      new Chart(this.chartTarget, {
          type: chartType,
          data: {
            labels: this.labels,
            datasets: this.dataset
          },
          options: {
              legend: {
                display: false
              },
              tooltips: {
                mode: 'index',
                displayColors: true,
                intersect: false
              },
              hover: {
                mode: 'index',
                intersect: false
              },
              scales: {
                  xAxes: [{
                      stacked: (graph == 'stacked'),
                      ticks: {
                          beginAtZero: true
                      }
                  }],
                  yAxes: [{
                      stacked: (graph == 'stacked'),
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              }
          }
      })
  }

  splitDateset(label, dataset, color) {
    var colors = dataset.map( () => { return color })
    let args = {
      fill: (this.chartType() == 'areas'),
      label: label,
      data: dataset,
      borderColor: colors,
      borderWidth: 3
    }

    if(this.chartType() === 'line') return args;

    args.backgroundColor = colors
    args.pointBackgroundColor = colors
    args.hoverBackgroundColor = colors
    return args
  }

  updateCategoriesOptions() {
    this.categoriesSelect[0].selectize.clear()
    this.categoriesSelect[0].selectize.clearOptions()
    if(event.target.value == 'movement') {
      this.categoriesSelect[0].selectize.addOption(
        window.categoriesOptions.movement_options
      )
    } else {
      this.categoriesSelect[0].selectize.addOption(
        window.categoriesOptions.stock_options
      )
    }
    this.categoriesSelect[0].selectize.refreshOptions()
  }
}
