import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    $(document).on('nested:fieldAdded', function(e){
      var tr = e.field
      $(tr).find('select:not(.product-input)').selectize()
      $(tr).find('select.product-input').selectize()
    })

    $('select.product-input, select.client-input').selectize()
    $('body').on('change', '.client-input', (e) => {
      var val = $(e.currentTarget).val()
      var tr = $(e.currentTarget).closest('tr')
      this.loadClientProducts(tr, val)
    })
  }

  loadClientProducts(row, clientId){
    var productsSelect = $(row).find('.product-input')[0].selectize
    productsSelect.clear()
    productsSelect.clearOptions()
    Api.loadCollection('/shipment_plans/products', { client_id: clientId })
      .then( function(data){
        $(data.collection).each( function(i, element){
          var option = { text: `${element.part_number} ${element.description}`, value: element.id, forPackage: element.forPackage };
          productsSelect.addOption(option);
        });
        productsSelect.refreshOptions(true);
      });
    }
}
