import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['report', 'message']

  connect() {
    let param_report_id = new URLSearchParams(window.location.search).get("report_id")
    if(param_report_id == this.reportTarget.value){
      this.pollForReportStatus()
    }
  }

  pollForReportStatus(){
    $.ajax({
      url: '/client_app/inventory/products/report_status',
      method: 'GET',
      data: { report_id: this.reportTarget.value },
      success: response => {
        if(response["file_status"]){
          this.messageTarget.style.display = "none";
          const link = document.createElement('a');
          link.href = response["file"];
          document.body.appendChild(link);
          link.click();
        }else{
          setTimeout(() => this.pollForReportStatus(), 3000);
        }
      }
    })
  }

}
