import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['productSelect']

  connect() {
    console.log('connect to stimulus product receiver')
    this.initSelectize()
  }

  initSelectize(){
    $(this.productSelectTarget).selectize({
      valueField: 'id',
      labelField: 'part_number',
      searchField: ['part_number','description'],
      load: function(query, callback) {
        $.ajax({
          url: '/products/fetch_products',
          type: 'get',
          data: { query: query },
          error: function(error){
            callback()
          },
          success: function(response){
            console.log(response)
            callback(response)
          }
        })
      }
    })
  }

}