import { Controller } from "stimulus"

export default class extends Controller {
  disconnect() {
  }

  connect() {
    this.setupScanner()
  }

  setupScanner() {
    this.scanner = new MainScan(this.onScanned.bind(this))
    this.scanner.url = "/scan_pick_packs/"
    this.scanner.method = 'GET'
    this.callback = this.onScanned.bind(this)
  }

  onScanned(data) {
    console.log(data)
    if(data.errors !== null ) {
      swal(data.errors)
      this.scanner.waitingStatus()
      this.scanner.listenScan = true
    } else if (data.pick_pack !== null){
      Turbolinks.visit(data.path, { action: 'replace' })
    } else {
      swal('Etiqueta no encontrada dentro de una solicitud pendiente de PICK PACK')
      this.scanner.waitingStatus()
      this.scanner.listenScan = true
    }
  }
}
