import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#lot_product_id').selectize().on('change', (e) => {
      this.setPerPallet(e)
      this.color(e)
    })
  }

  setPerPallet(e){
    var val = $(e.currentTarget).val()
    if(val == '') {
      $('#lot_per_package').val(0)
      return
    }
    var selectize = $(e.currentTarget)[0].selectize
    var object = selectize.options[parseInt(val)]
    var forPackage = object.for_package
    $('#lot_per_package').val(forPackage)
  }

  color(e) {
    var value = e.currentTarget.value;
    var parent = $(e.currentTarget).parent()
    var container = $(e.currentTarget).parent().find('.selectize-control')
    if(value == null || value == undefined || value == '') {
      container.removeClass('form-group-valid')
    } else {
      container.addClass('form-group-valid')
    }
  }
}
