// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

function valueInvalid({ value }) {
  return value == null || value == undefined || value == '';
}

function onChangeSelectize({ currentTarget }) {
  const container = $(currentTarget).parent().find('.selectize-control')
  if(valueInvalid(currentTarget)) {
    container.removeClass('form-group-valid')
  } else {
    container.addClass('form-group-valid')
  }
}

function onChangeEmptySelectize(e) {
  const { selectize } = $(this)[0];
  const items = selectize.getValue();
  const value = items[items.length - 1];
  const emptyIsNotFirstOption = value === '' && items.length > 1;
  const emptyItem = selectize.getItem('');
  const hasEmptyValue = value !== '' && emptyItem.length === 1
  if(emptyIsNotFirstOption || hasEmptyValue) {
    selectize.removeItem(value, true);
  }
}

export default class extends Controller {
  connect() {
    $('.selectize').selectize().on('change', onChangeSelectize);
    $('.empty-selectize')
      .selectize({ allowEmptyOption: true, items: [''] })
      .on('change', onChangeEmptySelectize);

    $('.datepicker').pickdate({
      format: 'dd/mm/yyyy',
      cancel: window.datePickerLocals.cancel,
      ok: window.datePickerLocals.apply,
      monthsFull: window.datePickerLocals.monthNames,
      monthsShort: window.datePickerLocals.shortMonthNames,
      weekdaysFull: window.datePickerLocals.dayNames,
      weekdaysShort: window.datePickerLocals.shortDayNames
    });

    $(document).on('nested:fieldAdded', function(event) {
      var field = event.field;
      var selectizeField = field.find('.selectize');
      selectizeField.selectize();
    });
  }

  disconnect() {
    $('.selectize').off('change');
    $('.empty-selectize').off('change');
    $(document).off('nested:fieldAdded');
  }
}
