import { Controller } from "stimulus"

export default class extends Controller {
  static targets =  ['widget']
  connect () {
    if(this.data.get('client')) {
      this.path = '/client_app/client_indicators'
    } else {
      this.path = '/indicators'
    }
  }

  resize () {
    fetch(`${this.path}/${this.data.get('id')}/resize_widget`)
    .then(response => response.json())
    .then(data => {
      this.widgetTarget.className = ''
      this.widgetTarget.classList.add(data['size'])
    })
  } 
}