import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('connected2')
    new WorkOrdersForm()
    var wolt_row = $('#wolts')
    $('body').on('change', '.tag-check', function(){
      var el = $(this)
      var total = 0;
      var card_body = el.closest('.card-body');
      var total_banner = card_body.find('.total-pieces >.amount');
      card_body.find('.tag-check:checked').each(function() {
        var $this = $(this);
        var quantity = parseInt($this.closest('tr').find('.quantity').text());
        total += quantity;
      });
      total_banner.text(total);
    });

    var last_options;
    var options_selected = wolt_row.find('.location-select').map(function(i, e){
      return $(e).val()
    }).get()

    $('body').on('focus', '.location-select', function(e){
      var element = $(this);
      var element_value = $(e.currentTarget).val() || 0;
      options_selected = wolt_row.find('.location-select').map(function(_, e){
        if ($(e).is(element)) {
          return undefined
        }
        return $(e).val()
      }).get()
      $.each(options_selected, function(_, value){
        $(e.currentTarget).find("option[value='"+value+"']").attr('disabled', true)
      })
      options_selected.push(element_value);
      last_options = options_selected
    })

    $('body').on('change', '.location-select', function(){
      $('.location-select').each(function(_, element){
        $.each(last_options, function(_, last_option){
          $(element).find("option[value='"+last_option+"']").attr('disabled', false)
        })
      })
      $('.location-select').each(function(_, element){
        $.each(options_selected, function(option){
          $(element).find("option[value='"+option+"']").attr('disabled', true)
        })
      })
    });
  }

  disconnect() {
    $('body').off('change', '.tag-check')
    $('body').off('change', '.location-select')
  }
}
