import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['clientSelect', 'clientSelectRow', 'adminDashboard']

  connect() {
    const target = this.clientSelectTarget.selectize;
    const admin = this.adminDashboardTarget.dataset.client
    this.dashboard = new Dashboard(target, admin);
    $(this.clientSelectTarget).on('change', this.dashboard.onChangeSelectize);
  }

  onFetchDashboardClick(e) {
    this.clientSelectRowTarget.classList.remove('d-none');
    this.dashboard.fetchDashboard(e);
  }
}
