import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var value = $('#product_product_type').val()
    $('.owner').toggleClass('invisible', value != 'logistics')
    this.toogleProductValue(value == 'logistics')
  }

  toggleOwner(e){
    var value = e.currentTarget.value
    $('.owner').toggleClass('invisible', value != 'logistics')
    this.toogleProductValue(value == 'logistics')
    value != 'logistics' ?  $('.owner').find('select')[0].selectize.clear() : null
  }

  toogleProductValue(isLogistic) {
    $('.logistics-value').toggleClass('invisible', !isLogistic)
    $('.marketer-value').toggleClass('invisible', isLogistic)

  }
}
