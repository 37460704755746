import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "total", "field", "errorDisplay", "submit", "assignedH",
    "toAssignH"]
  connect() {
    $('.assignment-quantity').trigger('change')
  }

  countTotal(e) {
    var total = this.fieldTargets.map( (input) => {
      return parseFloat(input.value || 0)
    }).reduce( (last, next, i, v) => {
      return last + next;
    }, 0);

    var toAssign = this.totalTarget.value - total

    this.toAssignHTarget.innerHTML = `Sin asignar: ${toAssign.toFixed(2)}`
    this.assignedHTarget.innerHTML = `Asignado: ${total.toFixed(2)}`
    if(total > this.totalTarget.value) {
      $(this.errorDisplayTarget).show()
      this.submitTarget.disabled = true
    } else {
      $(this.errorDisplayTarget).hide()
      this.submitTarget.disabled = false
    }
  }
}
