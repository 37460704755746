import { Controller } from "stimulus"
import {Sortable, Plugins} from '@shopify/draggable';


export default class extends Controller {

  static targets = [ 'container' ]

  connect(){
    this.swappable = new Sortable(this.containerTarget, {
      draggable: '.draggable-indicator',
      handle: '.draggable-handle',
      mirror: {
        appendTo: '.draggable-container',
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    })

    this.swappable.on('sortable:sorted', this.updateOrder.bind(this))
  }

  updateOrder(){
    setTimeout(function() {
      var i = 0
      var params = {}
      for(const widget of this.containerTarget.querySelectorAll('.draggable-indicator')){
        const id = widget.dataset.resizeIndicatorId
        console.log(id, i)
        params[id] = { indicator_order: i }
        i++;
      }

      this.update(params)
    }.bind(this), 600)
  }

  update(params) {
    $.ajax({
      url: '/indicators/update_order',
      method: 'POST',
      data: { indicators: params }
    })
  }
}
