import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "submitButton", "resultContainer" ]
  connect() {
    console.log('imports conected')
  }

  onCheckSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.resultContainerTarget.innerHTML = xhr.response;
    this.formTarget.action = '/shipment_plans/import';
    this.formTarget.removeAttribute('data-remote');
    this.formTarget.removeAttribute('data-action');
    setTimeout(() => this.submitButtonTarget.value = 'Importar', 300);
    this.submitButtonTarget.classList.remove('btn-info');
    this.submitButtonTarget.classList.add('btn-success');
  }

  onCheckError(event) {
    let [data, status, xhr] = event.detail;
    this.resultContainerTarget.innerHTML = xhr.response;
  }
}
