import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['shipmentId']
  connect() {
    var shipmentId = this.shipmentIdTarget.value
    new ShipmentsChannel(shipmentId, (data) => {
      var id = data.message.id
      var shippedQuantity = data.message.shipped_quantity
      var progress = data.message.progress
      var pending = shippedQuantity - progress

      var row = $(`tr[data-id='${id}']`)
      row.find('.quantity').text(shippedQuantity)
      row.find('.qprogress').text(progress)
      row.find('pending').text(pending)
      this.reloadPageIfComplete()
    })
  }

  reloadPageIfComplete() {
    var completed = $('td.pending').filter(function(item){
      item.innerHTML == '0'
    }).length

    console.log(completed, $('td.pending').length)
    if(completed == $('td.pending').length){
      Turbolinks.visit(window.location, { action: 'replace' })
    }
  }
}
